dmx.Component('socket', {

  initialData: {
    id: null,
    connected: false,
    disconnected: true,
  },

  attributes: {
    namespace: {
      type: String,
      default: '/',
    },
  },

  methods: {
    emit (eventName, data) {
      return this._socket.emit(eventName, data);
    },

    request (eventName, data) {
      return this._socket.emitWithAck(eventName, data);
    },
  },

  events: {
    connect: Event,
    disconnect: Event,
    connect_error: Event,
    message: Event,
  },

  render: false,

  init (node) {
    this._connectHandler = this._connectHandler.bind(this);
    this._disconnectHandler = this._disconnectHandler.bind(this);
    this._connectErrorHandler = this._connectErrorHandler.bind(this);
    this._messageHandler = this._messageHandler.bind(this);

    this._socket = dmx.Socket(this.props.namespace);
    this._socket.on('connect', this._connectHandler);
    this._socket.on('disconnect', this._disconnectHandler);
    this._socket.on('connect_error', this._connectErrorHandler);
    this._socket.onAny(this._messageHandler);

    if (node.textContent.trim()) {
      try {
        this._config = (window.Hjson ? Hjson : JSON).parse(node.textContent);
        
        Object.keys(this._config).forEach(eventName => {
          this._socket.on(eventName, ($param, cb) => {
            dmx.Flow.run(this._config[eventName], dmx.DataScope({ $param }, this)).then(data => {
              if (typeof cb == 'function') cb(data);
            });
          });
        });
      } catch (err) {
        console.error(err);
      }
    }
  },

  destroy () {
    this._socket.off('connect', this._connectHandler);
    this._socket.off('disconnect', this._disconnectHandler);
    this._socket.off('connect_error', this._connectErrorHandler);
    this._socket.offAny(this._messageHandler);
  },

  _updateData () {
    const { id, connected, disconnected } = this._socket;
    this.set({ id, connected, disconnected });
  },

  _connectHandler () {
    this._updateData();
    this.dispatchEvent('connect');
  },

  _disconnectHandler (reason) {
    this._updateData();
    this.dispatchEvent('disconnect', {}, { reason });
  },

  _connectErrorHandler (err) {
    this._updateData();
    this.dispatchEvent('connect_error', {}, { message: err.message });
  },

  _messageHandler (eventName, data) {
    this.dispatchEvent('message', {}, data, eventName);
  },

});
