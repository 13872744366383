dmx.Actions({

  'socket.emit': function (options) {
    options = this.parse(options);
    return dmx.Socket(options.namespace).emit(options.eventName, options.params);
  },

  'socket.request': function (options) {
    options = this.parse(options);
    return dmx.Socket(options.namespace).timeout(options.timeout || 10000).emitWithAck(options.eventName, options.params);
  },

});
