dmx.__sockets = Object.create(null);

dmx.Socket = function (namespace = '/') {
  let socket = dmx.__sockets[namespace];

  if (!socket) {
    let domain = '';
    const scriptNode = document.querySelector('[src$="socket.io/socket.io.js"]');

    if (scriptNode) {
      domain = scriptNode.getAttribute('src').replace(/\/?socket\.io\/socket\.io\.js/, '');
    }

    // https://socket.io/docs/v4/client-options/#transports
    socket = io(domain + namespace, {
      transports: ['websocket', 'polling'],
      // withCredentials: true,
    });

    socket.on('connect_error', () => {
      socket.io.opts.transports = ['polling', 'websocket'];
    });

    dmx.__sockets[namespace] = socket;
  }

  return socket;
}
